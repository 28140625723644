













































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getChannelMsgDetail, pageComment } from '@/api'
import { getPlatform } from '@/utils'
import { CommonModule } from '@/store/modules/common'
import moment from 'moment'
import { PreviewImage } from '@/utils/previewImg'
import { ImagePreview } from 'vant'
@Component({
  name: 'ChannelDetails',
  filters: {
    dateFormat: (value: any, pattern: any) => {
      return moment(value).format(pattern)
    }
  }
})
export default class ChannelDetails extends Vue {
  [x: string]: any
  ruleForm: any = {}

  commentList: any = []

  isGuidance = false

  pla = getPlatform()

  isContent = false

  emptyUrl = require('@/assets/Empty_NoData.png')

  richImages = []

  created() {
    // 频道详情
    this.getChannelMsgDetail()

    // 评论列表
    this.getPageComment()
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }
  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language || 'ug'
  }

  //   频道详情
  async getChannelMsgDetail() {
    const { data, code } = await getChannelMsgDetail({
      channelMsgId: this.$route.query.channelMsgId || 36
    })
    if (code == 1001) {
      this.ruleForm = { ...data }
      this.isContent = true
      const richImages = PreviewImage(data.msgText)
      this.ruleForm.msgText = richImages[0]
      this.richImages = richImages[1]
    }
  }

  //   评论列表
  async getPageComment() {
    const { data } = await pageComment({
      channelMsgId: this.$route.query.channelMsgId,
      pageNum: 1,
      pageSize: 100
    })
    this.commentList = data.list
  }

  showImg(e: { target: { tagName: string; src: any } }) {
    if (e.target.tagName == 'IMG') {
      ImagePreview({
        images: this.richImages,
        startPosition: (e.target.src.split('random=')[1] - 1) as any,
        loop: false,
        closeOnPopstate: true //页面回退关闭预览
      })
    }
  }

  // 下载
  handelDownload() {
    if (this.pla === 'weixin' || this.pla === 'qq') {
      this.isGuidance = true
    } else {
      this.isGuidance = false
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        const loadDateTime: any = new Date()
        window.location.href = 'allo.ios.app://' //schema链接或者universal link
        window.setTimeout(function() {
          //如果没有安装app,便会执行setTimeout跳转下载页
          const timeOutDateTime: any = new Date()
          if (timeOutDateTime - loadDateTime < 5000) {
            window.location.href = 'https://itunes.apple.com/app/1664635935' //ios下载页面
          } else {
            window.close()
          }
        }, 500)
      } else if (navigator.userAgent.match(/android/i)) {
        try {
          window.location.href = 'allo://tel.top/home/callshow?select=2' //schema链接或者universal link
          window.setTimeout(function() {
            const u = navigator.userAgent
            if (u.match(/(vivo|VIVO);?/i)) {
              window.location.href = 'vivomarket://details?id=com.allo.contacts' // vivo手机
            } else if (u.match(/(OPPO|oppo);?/i)) {
              window.location.href =
                'oppomarket://details?packagename=com.allo.contacts' // oppo手机
            } else if (u.match(/(HUAWEI|HONOR);?/i)) {
              window.location.href = 'appmarket://details?id=com.allo.contacts' // 华为手机
            } else if (u.match(/(XIAOMI|redmi);?/i)) {
              window.location.href = 'mimarket://details?id=com.allo.contacts' //小米手机
            } else {
              //安卓其他设备
              window.location.href = process.env.VUE_APP_ALLO // 下载地址
            }
          }, 500)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}
